import SectionHeader from "./SectionHeader";
import React from "react";
import styled from "styled-components/macro";
import SvgSquash from "./icons/icons/Squash";
import BulletList from "./BulletList";
import {spareTimeData} from "../data/sparetime";

const SpareTime = () => {
    return (
        <StyledSpareTime>
            <SectionHeader icon={<SvgSquash/>} headerText={"Freizeit"}/>
            <BulletList
                title={spareTimeData.title}
                items={spareTimeData.hobbies}
            />
        </StyledSpareTime>
    );
};
export default SpareTime;

const StyledSpareTime = styled.div``;
