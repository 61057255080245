import styled from "styled-components/macro";
import {Colors} from "../styles/Colors";
import * as React from "react";
import {Fonts} from "../styles/Fonts";
import LinkIconText from "./icons/components/LinkTextIcon";
import SvgMail from "./icons/icons/Mail";
import SvgLinkedin from "./icons/icons/Linkedin";
import SvgXing from "./icons/icons/Xing";
import MyImage from "./MyImage";
import {Viewports} from "../styles/Viewports";
import MyQr from "./MyQr";
import IconText from "./icons/components/IconText";
import SvgLocation from "./icons/icons/Location";
import SvgPhone from "./icons/icons/Phone";

const Sidebar = () => {
    return (
        <SidebarLayout>
            <SidebarContent>
                {/*
                <MyQr/>
*/}
                <MySelf>
                    <MyImage/>
                    <Name>Marcel Tinner</Name>
                    <JobTitle>Software Engineer</JobTitle>
                </MySelf>
                <ContactInfo>
                    <IconText icon={<SvgLocation/>} text={'Wetzikon'} fontType={"Light"} iconSize={14}/>
                    <LinkIconText
                        link={"mailto:tinnerma@gmail.com"}
                        text={"tinnerma@gmail.com"}
                        icon={<SvgMail/>}
                    />
                    {/*} <LinkIconText
                        link={"tel:+41 79 814 50 08"}
                        text={"+41 79 814 50 08"}
                        icon={<SvgPhone/>}
                    />*/}
                    <SocialMedia>
                        <LinkIconText
                            link={"https://www.linkedin.com/in/marcel-tinner-64844493/"}
                            text={"LinkedIn"}
                            icon={<SvgLinkedin/>}
                        />
                    </SocialMedia>
                </ContactInfo>
            </SidebarContent>
        </SidebarLayout>
    );
};

export default Sidebar;

const SidebarContent = styled.section`
  box-sizing: border-box;
  padding: 32px;
  grid-row-gap: 24px;
  display: grid;
  grid-template-rows: min-content min-content;
  height: 100%;

  ${Viewports.for("small")}, print {
    grid-auto-flow: column;
    grid-template-rows: unset;
    justify-content: space-around;
    align-items: center;
    padding: 8px;
  }

  @media print {
    justify-content: unset;
    grid-auto-flow: row ;
    height: 70%;
  }
`

const SidebarLayout = styled.aside`
  position: sticky;
  top: 0;
  justify-content: center;
  box-sizing: border-box;
  color: ${Colors.light};
  z-index: 100;
  background-color: ${Colors.darkBlue};
  height: 100vh;

  ${Viewports.for("small")}, print {
    height: 100%;
  }

  @media print {
    position: relative;
  }
`;

const Name = styled.h1`
  margin-top: 16px;
  ${Fonts.bold(28)};
  text-align: center;
  align-items: center;
  margin-bottom: 0;
`;

const JobTitle = styled.span`
  margin-top: 8px;
  ${Fonts.light(18)};
`;

const MySelf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 25px;
    margin: auto 0;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media print {
    display: none;
  }
`;
