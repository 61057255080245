import SectionHeader from "./SectionHeader";
import SectionEntry from "./SectionEntry";
import SvgEducation from "./icons/icons/Education";
import styled from "styled-components/macro";
import React from "react";
import {educationData} from "../data/education";

const Education = () => {
    return (
        <StyledEducation>
            <SectionHeader icon={<SvgEducation/>} headerText={"Ausbildung"}/>
            {educationData.map(({title, subTitle, timeRange, hide}) => <SectionEntry
                key={timeRange}
                timeRange={timeRange}
                title={title}
                subTitle={subTitle}
                hide={hide}
            />)}
        </StyledEducation>
    );
};
export default Education;

const StyledEducation = styled.div``;
