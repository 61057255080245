import React from "react";
import styled from "styled-components/macro";
import { Fonts } from "../styles/Fonts";

type Props = {
  title: string;
  items: { title: string; description: string }[];
};

const TopicList = ({ items, title }: Props) => {
  return (
    <ListSection>
      <Title>{title}</Title>
      <StyledTopicList>
        {items.map(item => (
          <ListEntry key={item.title}>
            <ItemTitle>{item.title}</ItemTitle>
            <ItemDescription>{item.description}</ItemDescription>
          </ListEntry>
        ))}
      </StyledTopicList>
    </ListSection>
  );
};
export default TopicList;
const ListSection = styled.section`
  margin-top: 12px;
`;

const StyledTopicList = styled.ul`
  padding-top: 4px;
  padding-left: 0;
  margin-top: 0;
`;
const ItemTitle = styled.span`
  ${Fonts.bold(16)}
`;
const ItemDescription = styled.span`
  margin-left: 16px;
`;
const Title = styled.h3``;
const ListEntry = styled.li`
  list-style: none;
  :not(:first-of-type) {
    margin-top: 4px;
  }
`;
