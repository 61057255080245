type EducationData = { timeRange: string, title: string, subTitle: string, hide?: true }

export const educationData: EducationData[] = [{
    timeRange: "Mai 2016 – Jan. 2017",
    title: "Certifcate of Advanced Studies (CAS) in Front End Engineering",
    subTitle: "HSR Hochschule für Technik Rapperswil"
}, {
    timeRange: "Sept. 2011 – Sept. 2014",
    title: "Bachelor of Science FHO in Computer Science",
    subTitle: "HSR Hochschule für Technik Rapperswil"
}, {
    timeRange: "Sept. 2010 – Juni 2011",
    title: "Zulassungsstudium",
    subTitle: "Juventus Zürich",
    hide: true
}]
