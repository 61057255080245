import { graphql } from "gatsby";
import * as React from "react";
import MainLayout from "../layouts/MainLayout";
import SEO from "../components/SEO";
import GlobalStyles from "../styles/GlobalStyles";
import Sidebar from "../components/Sidebar";
import { Normalize } from "styled-normalize";
import MainContent from "../components/MainContent";

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
        author: string;
      };
    };
  };
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const IndexPage = ({ data }: IndexPageProps) => {

  return (
    <MainLayout>
      <Normalize />
      <SEO title={'CV'} />
      <GlobalStyles />
      <Sidebar />
      <MainContent />
    </MainLayout>
  );
};

export default IndexPage;
