import React from "react";
import { Fonts, FontSize, FontType } from "../../../styles/Fonts";
import styled from "styled-components/macro";

export type TextIconProps = {
  icon: JSX.Element;
  iconSize?: number;
  iconColor?: string;
  text: string;
  fontSize?: FontSize;
  fontType?: FontType;
  spaceBetween?: number;
  onClick?: React.MouseEventHandler<any>;
};

const IconText = ({
  icon,
  iconSize = 24,
  iconColor = "inherit",
  text,
  fontSize = 14,
  fontType = "Regular",
  spaceBetween = 10,
  onClick
}: TextIconProps) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon size={iconSize} color={iconColor}>
        {icon}
      </Icon>
      <Text fontType={fontType} fontSize={fontSize} spaceBetween={spaceBetween}>
        {text}
      </Text>
    </Wrapper>
  );
};

export default IconText;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div<{ size: number; color: string }>`
  font-size: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: ${({ color }) => color};
`;

// eslint-disable-next-line no-unexpected-multiline
export const Text = styled.div<{
  fontType: FontType;
  fontSize: FontSize;
  spaceBetween: number;
}>`
  ${({ fontSize, fontType }) => Fonts.byType(fontType, fontSize)};
  padding-left: ${({ spaceBetween }) => spaceBetween}px;
`;
