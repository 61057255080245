import styled from "styled-components/macro";
import React from "react";
import {Colors} from "../styles/Colors";
import {Fonts} from "../styles/Fonts";
import {css} from "styled-components";

type Props = {
    timeRange: string;
    title: string;
    subTitle?: string;
    description?: string;
    bullets?: string[]
    hideForPrint?: boolean
    hide?: true
};

const SectionEntry = ({timeRange, title, description, subTitle, bullets, hideForPrint = false, hide}: Props) => {
    if (hide) {
        return null
    }
    return (
        <StyledSectionEntry hideForPrint={hideForPrint}>
            <TimeRange>{timeRange}</TimeRange>
            <Title>{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            {description && <Description hideForPrint={!!bullets?.length}>{description}</Description>}
            {bullets && <BulletList>
                {bullets?.map((bullet) => <Bullet key={bullet}>{bullet}</Bullet>)}
            </BulletList>}
        </StyledSectionEntry>
    );
};

export default SectionEntry;

const StyledSectionEntry = styled.div<{ hideForPrint: boolean }>`
  @media print {
    break-inside: avoid;
  }
  padding-top: 20px;
  padding-left: 27px;
  color: ${Colors.darkGrey};
  border-left: 2px solid ${Colors.grey};
  margin-left: 11px;

  :first-of-type {
    padding-top: 12px;
  }

  ${({hideForPrint}) =>
          hideForPrint && '@media print { display: none}'
  }
`;


const bubbleStyles = css`
    content: "";
    position: absolute;
    left: -34px;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.grey};
    border-image: initial;
    background: ${Colors.light};
  `

const TimeRange = styled.span`
  position: relative;
  ${Fonts.light(15)};
  color: ${Colors.darkGrey};

  :before {
    ${bubbleStyles};
  }

`;
const Title = styled.h2`
  margin-top: 4px;
  color: ${Colors.anthracite};
  ${Fonts.bold(18)};
`;
const SubTitle = styled.h3`
  margin-top: 4px;
  color: ${Colors.anthracite};

  ${Fonts.regular(16)};
`;
const Description = styled.div<{ hideForPrint: boolean }>`
  margin-top: 4px;
  color: ${Colors.anthracite};
  ${Fonts.regular(13)};
  font-style: italic;

  ${({hideForPrint}) =>
          hideForPrint && '@media print { display: none}'
  }
`;

const BulletList = styled.ul`
  margin-top: 4px;
  margin-bottom: 0;
  row-gap: 4px;
  padding: 0;
  display: none;
  @media print {
    display: grid;
  }
`

const Bullet = styled.li`
  color: ${Colors.anthracite};
  ${Fonts.regular(13)};
  font-style: italic;
  position: relative;
  margin-left: 24px;
  
  ::marker {
    font-size: 18px;
    color: ${Colors.grey};
    border-color: transparent;
  }
`
