// eslint-disable-next-line no-restricted-imports
import { css } from "styled-components";

export type FontType = "Regular" | "Light" | "Bold";
export type FontSize =
  | 12
  | 13
  | 14
  | 15
  | 16
  | 18
  | 20
  | 22
  | 24
  | 28
  | 32
  | 36
  | "inherit";

export const Fonts = {
  regular: (fontSize: FontSize) => Font("Regular", fontSize),
  light: (fontSize: FontSize) => Font("Light", fontSize),
  bold: (fontSize: FontSize) => Font("Bold", fontSize),
  byType: (fontType: FontType, fontSize?: FontSize) => Font(fontType, fontSize)
};

const Font = (fontType: FontType, fontSize: FontSize = "inherit") => {
  return css`
    letter-spacing: ${getLetterSpacing(fontSize)};
    font-size: ${getFontSize(fontSize)};
    font-weight: ${getFontWeight(fontType)};
  `;
};

const getFontWeight = (fontType: FontType) => {
  switch (fontType) {
    case "Regular":
      return "400";
    case "Light":
      return "200";
    case "Bold":
      return "600";
  }
};

const getLetterSpacing = (fontSize: FontSize) => {
  if (fontSize > 24) {
    return "auto";
  } else if (fontSize >= 20) {
    return "0.2px";
  } else if (fontSize >= 12) {
    return "0.4px";
  }
  return "initial";
};

export const getFontSize = (fontSize: FontSize) => {
  switch (fontSize) {
    case "inherit":
      return fontSize
    default:
      return `${fontSize/16}em`
  }
};
