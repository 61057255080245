import SectionHeader from "./SectionHeader";
import React from "react";
import styled from "styled-components/macro";
import BulletList from "./BulletList";
import SvgSkills from "./icons/icons/Skills";
import TopicList from "./TopicList";
import {skillList} from "../data/skills";
import {languageData} from "../data/language";

const Skills = () => {
    return (
        <StyledSkills>
            <SectionHeader
                icon={<SvgSkills/>}
                headerText={"Kenntnisse und Fähigkeiten"}
            />
            {
                skillList.map(({title, skills}) => <BulletList key={title} title={title} items={skills}/>)
            }
            <TopicList
                title={languageData.title}
                items={languageData.language}
            />
        </StyledSkills>
    );
};
export default Skills;

const StyledSkills = styled.div``;
