import { createGlobalStyle } from "styled-components";
import { Colors } from "./Colors";

const GlobalStyles = createGlobalStyle`
  body {
     font-family: 'Fira Sans', sans-serif;
     color: ${Colors.anthracite};
     background-color: ${Colors.light};
    @media print {
      font-size: 0.85em;
    }
  }

h1, h2, h3, h4 {color: inherit; margin-bottom: 0; margin-top: 0}

  a {
    text-decoration: none;
  }
`;

export default GlobalStyles;
