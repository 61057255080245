import React, { ReactNode } from "react";

import styled from "styled-components/macro";
import {Viewports} from "../styles/Viewports";

type Props = { children: ReactNode };

const MainLayout = ({ children }: Props) => (
  <StyledLayout>{children}</StyledLayout>
);

export default MainLayout;

const StyledLayout = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 280px 1fr;
  position: relative;


  ${Viewports.for("small")}, print {
    grid-template-columns: unset;
    grid-template-rows: 200px 1fr;
    justify-content: space-around;
    align-items: center;
    width: auto;
  }

  @media print {
    grid-template-rows: 160px 1fr;
  }
`;
