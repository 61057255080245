export type IColors = {
  darkBlue: string;
  darkGrey: string;
  greyBlue: string;
  anthracite: string;
  grey: string;
  light: string;
};

export const Colors: IColors = {
  darkBlue: "#1D517B",
  darkGrey: "#716155",
  greyBlue: "#8C918B",
  anthracite: "#3a3a3a",
  grey: "rgba(113, 97, 85, 0.3)",
  light: "#F4F7F7"
};
