import styled from "styled-components/macro";
import IconText from "./icons/components/IconText";
import React from "react";
import { Colors } from "../styles/Colors";

type Props = {
  icon: React.FunctionComponentElement<SVGSVGElement>;
  headerText: string;
};

const SectionHeader = ({ icon, headerText }: Props) => {
  return (
    <StyledSectionHeader>
      <IconText
        icon={icon}
        text={headerText}
        fontType={"Bold"}
        fontSize={20}
        spaceBetween={16}
      />
    </StyledSectionHeader>
  );
};

export default SectionHeader;

const StyledSectionHeader = styled.header`
  margin-top: 32px;
  margin-bottom: 4px;
  color: ${Colors.darkGrey};
`;
