import * as React from "react";

const SvgSquash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 640 512" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M125.9 346.5l56.7 80-120.7 82.6c-7.2 5-17.2 3.3-22.2-3.9L2.9 453.4c-5.1-7.2-3.4-17.1 3.7-22.3.1.1 119.3-84.6 119.3-84.6zm402.8-16.7c-49.1 34.8-103.9 52-153.5 52-27.6 0-84.5-15.1-171.9 18.6l-45.7-64.5c32.7-38.2 56.7-83.2 67.7-132.7 12.2-54.7 49.5-110.5 106.8-151.1 100.5-71.2 228.2-70.2 283.4 7.7 54.3 76.7 15.4 197.6-86.8 270zm-271.2 5.9c-11.7-12.3-12-11.7-21.2-29.7-7.9 14.1-16.3 27.9-26.2 41 15.7-4.9 31.5-8.5 47.4-11.3zM485.9 64c-106.4 0-178.6 90-195 141.1-21.3 66.2 13.3 112.8 83.9 112.8 84.1 0 171.1-66.8 195-141.1C591.1 110.7 556.5 64 485.9 64z"
    />
  </svg>
);

export default SvgSquash;
