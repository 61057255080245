import styled from "styled-components/macro";
import * as React from "react";
import Education from "./Eduction";
import Work from "./Work";
import SpareTime from "./SpareTime";
import Skills from "./Skills";
import Projects from "./Projects";
import {Viewports} from "../styles/Viewports";

const MainContent = () => {
    return (
        <StyledMainContent>
            <Work/>
            <Projects/>
            <Education/>
            <Skills/>
            <SpareTime/>
        </StyledMainContent>
    );
};
export default MainContent;

const StyledMainContent = styled.main`
  padding: 0 2em 1em;

  ${Viewports.for("small")} {
    margin-left: auto;
  }
`;
