import styled from "styled-components/macro";
import React from "react";
import { Colors } from "../styles/Colors";
import { Fonts } from "../styles/Fonts";
import IconWrapper from "./icons/components/IconWrapper";
import SvgExternalLink from "./icons/icons/ExternalLink";

type Props = {
  timeRange: string;
  title: string;
  description?: string;
  link?: { text: string; href: string };
  hide?:true
};

const ProjectEntry = ({ timeRange, title, description, link, hide }: Props) => {
  if(hide)
    return null

  return (
    <StyledSectionEntry>
      <TimeRange>{timeRange}</TimeRange>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {link && (
        <Link href={link.href} target="_blank" rel="noreferrer">
          <LinkText>{link.text}</LinkText>
          <IconWrapper icon={<SvgExternalLink />} />
        </Link>
      )}
    </StyledSectionEntry>
  );
};

export default ProjectEntry;

const StyledSectionEntry = styled.div`
  padding-top: 20px;
  padding-left: 27px;
  color: ${Colors.darkGrey};
  border-left: 2px solid ${Colors.grey};
  margin-left: 11px;

  :first-of-type {
    padding-top: 12px;
  }
`;

const TimeRange = styled.span`
  position: relative;
  ${Fonts.light(15)};
  color: ${Colors.darkGrey};

  &:before {
    content: "";
    position: absolute;
    left: -34px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${Colors.grey};
    border-image: initial;
    background: rgb(255, 255, 255);
  }
`;
const Title = styled.h2`
  margin-top: 4px;
  color: ${Colors.anthracite};
  ${Fonts.bold(18)};
`;
const Description = styled.pre`
  white-space: pre-line;
  margin-top: 4px;
  color: ${Colors.anthracite};
  ${Fonts.regular(15)};
  font-style: italic;
  font-family: "Fira Sans";
  margin-bottom: 0;
`;

const Link = styled.a`
  display: inline-flex;
  margin-top: 4px;
  color: ${Colors.darkGrey};
  ${Fonts.regular(13)};
`;

const LinkText = styled.span`
  margin-right: 4px;
`;
