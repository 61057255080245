import React from "react";
import IconText from "./IconText";
import styled from "styled-components/macro";
import { Colors } from "../../../styles/Colors";

type Props = {
  size?: number;
  color?: string;
  text: string;
  icon: React.FunctionComponentElement<SVGSVGElement>;
  link: string;
};

const LinkIconText = ({
  link,
  size,
  color = Colors.light,
  icon,
  text
}: Props) => {
  return (
    <Link href={link} color={color} rel="noreferrer">
      <IconText icon={icon} text={text} fontType={"Light"} iconSize={14} />
    </Link>
  );
};
export default LinkIconText;

const Link = styled.a<{ color: string }>`
  margin-top: 16px;
  color: ${({ color }) => color};
`;
