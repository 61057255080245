type LanguageData = { title: string, language: { title: string, description: string }[] }

export const languageData: LanguageData = {
    title: 'Sprachen', language: [
        {title: "Deutsch", description: "Muttersprache"},
        {
            title: "Englisch",
            description: "Gute Kenntnisse in Wort und Schrift (B2)"
        }
    ]
}