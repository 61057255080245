import React from "react";
import styled from "styled-components/macro";

type Props = {
  size?: number;
  color?: string;
  icon: React.FunctionComponentElement<SVGSVGElement>;
};

const IconWrapper = ({ size, color, icon }: Props) => {
  return (
    <StyledIcon size={size} color={color}>
      {icon}
    </StyledIcon>
  );
};

export default IconWrapper;

const StyledIcon = styled.div<{ size?: number; color?: string }>`
  font-size: ${props => props.size}px;
  display: flex;
  align-items: center;
  color: ${props => props.color || "inherit"};
`;
