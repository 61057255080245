import React from "react";
import styled from "styled-components/macro";

type Props = {
  title: string;
  items: string[];
};

const BulletList = ({ items, title }: Props) => {
  return (
    <ListSection>
      <Title>{title}</Title>
      <StyledBulletList>
        {items.map(item => (
          <BulletItem key={item}>{item}</BulletItem>
        ))}
      </StyledBulletList>
    </ListSection>
  );
};
export default BulletList;
const ListSection = styled.section`
  margin-top: 12px;
`;

const StyledBulletList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-left: 0;
  margin-top: 0;
`;
const BulletItem = styled.li`
  list-style: none;
  :not(:last-of-type) {
    :after {
      margin-left: 8px;
      margin-right: 8px;
      content: "•";
    }
  }
`;
const Title = styled.h3``;
