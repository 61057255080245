type ViewportRange = { from: number; to?: number };

const smallRange: ViewportRange = { from: 0, to: 638 };

export type Viewport = "small";

const forViewport: { [key in Viewport]: string } = {
  small: `only screen and (min-width:${smallRange.from}px) and (max-width: ${smallRange.to}px)`
};

export const Viewports = {
  for: (viewport: Viewport) => `@media ${forViewport[viewport]}`
};
